import { OrbitControls, Environment, Float, PresentationControls, ContactShadows, Text, useGLTF } from '@react-three/drei'
import { Suspense, useMemo, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import Laptop from './Laptop'

export default function Experience() {

    const [link, setLink] = useState(0);
    const linksList = useMemo(() => [
        "https://simple-portfolio-r3f-dun.vercel.app/",
        "https://marble-run-flo.vercel.app/",
        "https://portal-three-r3f.vercel.app/",
        "https://particles-cursor-animation-cyan.vercel.app/",
        "https://galaxy-ashen-five.vercel.app/"
    ], []);

    const text = useRef();

    useFrame((state, delta) => {
        text.current.rotation.y += delta * 0.5
    })

    const githubLogo = useGLTF('/models/logos/github.glb');
    const linkedinLogo = useGLTF('/models/logos/linkedin.glb');

    const trophy = useGLTF('/models/trophy.glb');
    trophy.nodes.error.visible = false;

    const hyperlinkModel = useGLTF('/models/link/link.glb');

    const changeSite = () => {
        const nextLinkIndex = (link + 1) % linksList.length;
        setLink(nextLinkIndex);
    }

    return <>

        <color args={["#241a1a"]} attach="background" />

        <Environment preset='city' />

        {/* <OrbitControls makeDefault /> */}
        <PresentationControls
            global
            rotation={[0.13, 0.1, 0]}
            polar={[-0.4, 0.2]}
            speed={5}
            azimuth={[-1, 0.75]}
            config={{ mass: 8, tension: 100 }}
            snap={{ mass: 5, tension: 800 }}
        >
            <Float rotationIntensity={0.4}>
                <rectAreaLight
                    width={2.5}
                    height={1.65}
                    intensity={65}
                    color={"#ffffff"}
                    position={[0, 0.55, -1.15]}
                    rotation={[-0.1, Math.PI, 0]}
                />
                <Suspense fallback={null}>
                    <Laptop
                        position-y={- 1.2}
                        rotation-x={0.13}
                        link={linksList[link]}
                    />
                </Suspense>
                <Text
                    ref={text}
                    font='./bangers-v20-latin-regular.woff'
                    position={[1.5, 1.25, 0.75]}
                    rotation-y={-1}
                    scale={0.6}
                    maxWidth={5}
                    textAlign="center"
                    anchorX="center"
                    anchorY="middle"
                >
                    Florentin Farcy
                </Text>
                <Text
                    font='./bangers-v20-latin-regular.woff'
                    position={[2.5, -0.5, 0.75]}
                    rotation-y={-0.7}
                    scale={0.6}
                    maxWidth={5}
                    onClick={changeSite}
                >
                    Next
                </Text>
                <primitive
                    object={hyperlinkModel.scene}
                    position={[3.5, -0.25, 1.5]}
                    rotation={[Math.PI * 0.5, 2.5, 2]}
                    scale={0.25}
                    onClick={() => window.open(linksList[link], '_blank')}
                    onPointerEnter={() => { document.body.style.cursor = "pointer" }}
                    onPointerLeave={() => { document.body.style.cursor = "default" }}
                />
            </Float>
        </PresentationControls>

        <primitive
            object={githubLogo.scene}
            position={[-2.5, 1.5, 0]}
            scale={0.1}
            onClick={() => window.open('https://github.com/FloFAR', '_blank')}
            onPointerEnter={() => { document.body.style.cursor = "pointer" }}
            onPointerLeave={() => { document.body.style.cursor = "default" }}
        />

        <primitive
            object={linkedinLogo.scene}
            position={[-3.25, 0.5, 0]}
            scale={0.1}
            onClick={() => window.open('https://www.linkedin.com/in/florentin-farcy', '_blank')}
            onPointerEnter={() => { document.body.style.cursor = "pointer" }}
            onPointerLeave={() => { document.body.style.cursor = "default" }}
        />

        <primitive
            object={trophy.scene}
            position={[-2.4, -1, 0]}
            rotation={[0, -0.5, 0]}
            scale={0.3}
            onClick={() => window.open('https://threejs-journey.com/certificate/view/40558', '_blank')}
            onPointerEnter={() => { document.body.style.cursor = "pointer" }}
            onPointerLeave={() => { document.body.style.cursor = "default" }}
        />

        <ContactShadows
            position-y={-1.4}
            opacity={0.8}
            scale={15}
            blur={0.8}
        />

    </>
}