import { Html } from '@react-three/drei'
import { useEffect, useRef } from 'react';

export default function Screen({ link }) {

    const iframeRef = useRef();

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.src = link;
        }
    }, [link]);

    return <Html
        transform
        wrapperClass='htmlScreen'
        distanceFactor={1.17}
        position={[0.005, 1.54, -1.4]}
        rotation-x={-0.256}
        scale={0.95}
    >
        <iframe ref={iframeRef} src={link} />
    </Html>
}